body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: content-box;
  height: 100vh;

  * {
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  #root {
    height: 100%;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.not-found-warning,
.upload-warning {
  color: #df081d;
  text-align: center;
}

.upload-success {
  color: #20842d;
  text-align: center;
  font-weight: bold;
}

/*======================
* === LAYOUT STYLING === 
* ======================
*/
#layout-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  #header-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
    max-width: 800px;
    position: relative;
    width: 100%;
    margin: 0 auto;

    #logo {
      height: 5em;
      max-height: 250px;
    }

    #logout-icon {
      cursor: pointer;
      position: absolute;
      right: 20px;
    }
  }

  #content-wrapper {
    width: 90%;
    max-width: 800px;
    margin: 0 auto;
    height: 90%;
    padding: 5%;
    overflow: scroll;
    position: relative;
  }
}

/*======================
* === BUTTON STYLING === 
* ======================
*/
.button-component {
  cursor: pointer;
  background: #0669af;
  color: white;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  border-radius: 5px;
  text-transform: uppercase;
  width: calc(100% - 30px);
  margin: 15px 0;

  &.disabled {
    cursor: not-allowed;
    background: #0b4c7a;
    color: darkgray;
  }
}

/*======================
* ==== INPUT STYLING === 
* ======================
*/
.input-component {
  width: 100%;
  margin: 15px 0;

  input {
    width: calc(100% - 30px);
    padding: 0;
    margin: 0;
    box-shadow: none;
    border: none;
    background-color: #86929b62;
    padding: 15px;
    border-radius: 5px;

    &:focus,
    &:focus-visible,
    &:focus-within {
      outline: none;
    }
  }
}

/*========================
* === DROPDOWN STYLING === 
* ========================
*/
.dropdown-style,
.dropdown-wrapper {
  padding: 10px 0;
}

.dropdown-component--value-wrapper {
  background: #86929b62;
  padding: 5px;
}

.dropdown-clear {
  display: none;
}

/*========================
* === DROPDOWN STYLING === 
* ========================
*/
.loading-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  &.small {
    width: 100%;
    height: 1rem;

    .lds-ellipsis div {
      background: white !important;
    }
  }

  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #0669af;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
}

/*===========================
* === SELECT LIST STYLING === 
* ===========================
*/
.select-list-component {
  height: 60%;
  overflow: scroll;
  position: relative;

  &--title {
    position: sticky;
    top: 0;
    background: white;
    padding: 15px 0;
    margin: 0;
  }

  &--entry {
    padding: 15px 5px;
    border: 1px solid lightgray;
    border-radius: 5px;
    margin: 5px;
    cursor: pointer;

    &.no-click {
      cursor: default;
    }
  }
}

/*========================
* ==== POPUP STYLING =====
* ========================
*/
.popup-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  padding: 15px;
  background-color: white;
  display: flex;
  flex-direction: column;

  b {
    margin-bottom: 20px;
  }
}
