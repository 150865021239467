// Generated with util/create-component.js

.dropdown-component {
  &--value {
    height: 30px;
    width: 100%;
    border: none;
    outline: none;
    overflow: hidden;
    box-sizing: border-box;
    color: black;
    background-color: transparent;
    cursor: pointer;
    &::placeholder {
      opacity: 0.6;
    }
    &:disabled {
      opacity: 0.5;
      pointer-events: none;
      cursor: not-allowed;
    }
  }
  .no-lower-opacity {
    &:disabled {
      opacity: 1;
    }
  }
  &--required-trigger {
    @extend .dropdown-component--value;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }
  .no-pointer-events {
    pointer-events: none;
  }
  &--value-wrapper {
    position: relative;
    background-color: lightblue;
  }
  &--clear {
    position: absolute;
    top: 5px;
    right: 0;
    width: 20px;
    height: 20px;
    cursor: pointer;
    z-index: 1;
  }
  &--options {
    border: 1px solid lightblue;
    background-color: white;
    z-index: 5;
    max-height: 150px;
    overflow-y: scroll;
    &--option {
      cursor: pointer;
      border-bottom: 1px solid lightblue;
      &:hover {
        background-color: lightblue;
      }
      &:last-of-type {
        border-bottom: none;
      }
    }
    .disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}
